
import { computed, ref } from 'vue';
import { generalStore } from '@/store';
import { Language, TranslationGeneral } from '@/models/Interfaces';
import Multiselect from '@vueform/multiselect';
import { api } from '@/services/Api';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import swal from 'sweetalert2';
import Q from 'q';

export default {
    name: 'EditTranslation',
    props: {
        entity: {
            type: String
        },
        type: {
            type: String
        },
        id: {
            type: String
        }
    },
    components: {
        Multiselect
    },
    async setup(props: any) {
        const langs = computed<Language[]>(() => generalStore.getters.languages);
        const currentLanguage = computed(() => generalStore.getters.currentLanguage);
        const selectedOption = ref();
        const { t } = useI18n();
        if (!langs.value.length) {
            await generalStore.dispatch('loadAllLanguages');
        }
        selectedOption.value = langs.value.find(l => l.name == currentLanguage.value)?.id;
        const showInfo = ref(false);
        const currentType = ref(props.type);
        const entity = ref<string>(props.entity);
        const id = ref<number>(props.id);
        const translations = ref<TranslationGeneral[]>([]);
        if (entity.value == 'BusinessClient') {
            swal.showLoading();
            const apiPromise = api.getBusinessClientTranslation(id.value);
            await Q.delay(400);
            const response = await apiPromise;
            if (response.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: response.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (response.data) {
                translations.value = response.data;
                swal.close();
            }
        }
        function AddMissingTranslation() {
            langs.value.forEach(lang => {
                const transition = translations.value.find(x => x.languageId == lang.id);
                if (transition == null) {
                    const translation: TranslationGeneral = {
                        name: '',
                        languageId: lang.id,
                        entityId: id.value,
                        id: 0,
                        languageName: lang.name
                    };
                    translations.value.push(translation);
                }
            });
        }
        AddMissingTranslation();
        function goBack() {
            switch (entity.value) {
                case 'BusinessClient':
                    router.push({ name: 'businessClientEdit', params: { id: id.value, page: 1 } });
                    break;
            }
        }
        async function updateBusinessClientName() {
            swal.showLoading();
            const apiPromise = api.updateBusinessClientName(id.value, translations.value);
            await Q.delay(400);
            const response = await apiPromise;
            if (response.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: response.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (response.data) {
                swal.close();
            }
            goBack();
        }
        function saveTranslation() {
            if (currentType.value === 'businessClientName') {
                updateBusinessClientName();
            }
        }
        function checkDescription(descriptionType: string) {
            if (descriptionType == 'businessClientName') {
                translations.value.forEach(translation => {
                    if (translation.name.length > 50) {
                        translation.name = translation.name.substring(0, 50);
                    }
                });
            }
        }
        return {
            currentType,
            langs,
            goBack,
            selectedOption,
            showInfo,
            translations,
            currentLanguage,
            checkDescription,
            saveTranslation
        };
    }
};
